import { useSelector } from 'react-redux';
import { getFeedbackState } from '../../store/feedback/selectors';
import AppModal from '../common/AppModal';

import FeedbackForm from './FeedbackForm';
import FeedbackTitle from './FeedbackTitle';

interface FeedbackModalProps {
  onClose: () => void;
}

const FeedbackModal = ({ onClose }: FeedbackModalProps) => {
  const { status } = useSelector(getFeedbackState);
  return (
    <AppModal
      centered
      onClose={onClose}
      withCloseIcon
      titleComponent={<FeedbackTitle />}
      loading={status === 'loading'}>
      <FeedbackForm closeCallback={onClose} />
    </AppModal>
  );
};

export default FeedbackModal;
