import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  setIsUserRegistered,
  toggleRegisterPopup
} from '../../store/calculator/actions';
import { loginUser } from '../../store/user/actions';

interface Inputs {
  email: string;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Email format is incorrect')
});

const ReturningForm = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, touchedFields }
  } = useForm<Inputs>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: { email: '' }
  });

  const hasError = (inputName: keyof Inputs) =>
    errors[inputName] ? true : false;
  const getError = (inputName: keyof Inputs) => errors[inputName]?.message;

  const getInputClass = (inputName: keyof Inputs) =>
    hasError(inputName) ? 'error' : touchedFields[inputName] ? 'success' : '';

  const onSubmit = ({ email }: Inputs) => {
    const callback = (err?: string) => {
      if (err) {
        setError('email', { message: err });
        return;
      }

      dispatch(setIsUserRegistered(true));
      dispatch(toggleRegisterPopup(false));
    };

    dispatch(loginUser(email, callback));
  };

  const onError = (err: typeof errors) => {
    //console.log(err.password);
  };

  return (
    <Stack alignItems="center" component="form">
      <Typography textAlign="justify" fontSize={14} mb={4} width="80%">
        The email address collected on this website is not shared with or sold
        to third parties. It is collected for the purpose of accessing the
        website. We will only contact you with consent. The calculator data is
        not retained.
      </Typography>
      <TextField
        {...register('email')}
        size="small"
        type="email"
        placeholder="Email"
        fullWidth
        className={`calc-field ${getInputClass('email')}`}
        error={hasError('email')}
        helperText={getError('email')}
        FormHelperTextProps={{
          sx: {
            ml: 0,
            bgcolor: 'white'
          }
        }}
      />
      <Button
        disabled={false}
        onClick={handleSubmit(onSubmit, onError)}
        className="calc-btn filled"
        sx={{ width: 230, mb: 3, mt: 3 }}
        endIcon={<ArrowForwardIcon />}>
        Submit
      </Button>
    </Stack>
  );
};

export default ReturningForm;
