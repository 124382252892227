import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { appColors } from '../../theme';

const FeedbackTitle = () => {
  return (
    <Box
      textAlign="center"
      mt={3}
      pb={1}
      mx={4}
      borderBottom={`3px solid ${appColors.primary}`}>
      <Typography fontSize={24} fontWeight="bold" color="primary">
        Feedback
      </Typography>
    </Box>
  );
};

export default FeedbackTitle;
