import {
  CalculatorAction,
  CalculatorState,
  CLEAR_CALC_CONTENTS,
  SET_IS_USER_REGISTERED,
  SET_QUESTION_ANSWER,
  SET_TIME_UNIT,
  SET_TOTAL,
  SET_YES_NO_QUESTION_ANSWER,
  TOGGLE_REGISTER_POPUP
} from './types';

const initialState: CalculatorState = {
  isRegisterPopupOpen: false,
  isUserRegistered: false,
  unitOfTime: 'hours',
  questionOne: '',
  questionTwo: '',
  questionThree: '',
  yesNoQuestionOne: null,
  yesNoQuestionTwo: null,
  yesNoQuestionThree: null,
  total: ''
};

const calculatorReducer = (
  state = initialState,
  action: CalculatorAction
): CalculatorState => {
  switch (action.type) {
    case SET_TIME_UNIT:
      return { ...state, unitOfTime: action.payload.unit };
    case SET_QUESTION_ANSWER:
      return { ...state, [action.payload.key]: action.payload.answer };
    case SET_YES_NO_QUESTION_ANSWER: {
      const { key, answer } = action.payload;

      let yesNoQuestionTwo =
        key === 'yesNoQuestionTwo' ? answer : state.yesNoQuestionTwo;
      let yesNoQuestionThree =
        key === 'yesNoQuestionThree' ? answer : state.yesNoQuestionThree;

      // if (key === 'yesNoQuestionOne' && answer === 'no') {
      //   yesNoQuestionTwo = null;
      //   yesNoQuestionThree = null;
      // } else if (key === 'yesNoQuestionTwo' && answer === 'no') {
      //   yesNoQuestionThree = null;
      // }

      return {
        ...state,
        [action.payload.key]: action.payload.answer,
        yesNoQuestionTwo,
        yesNoQuestionThree
      };
    }
    case TOGGLE_REGISTER_POPUP:
      return { ...state, isRegisterPopupOpen: action.payload.isOpen };
    case SET_IS_USER_REGISTERED:
      return { ...state, isUserRegistered: action.payload.isRegistered };
    case SET_TOTAL:
      return { ...state, total: action.payload.total };
    case CLEAR_CALC_CONTENTS:
      return {
        ...state,
        questionOne: '',
        questionTwo: '',
        questionThree: '',
        yesNoQuestionOne: null,
        yesNoQuestionTwo: null,
        yesNoQuestionThree: null,
        total: ''
      };
    default:
      return state;
  }
};

export default calculatorReducer;
