import { Dispatch } from 'react';
import { AppThunk } from '../index';
import {
  CalculatorAction,
  CalculatorState,
  CLEAR_CALC_CONTENTS,
  SET_IS_USER_REGISTERED,
  SET_QUESTION_ANSWER,
  SET_TIME_UNIT,
  SET_TOTAL,
  SET_YES_NO_QUESTION_ANSWER,
  TimeUnit,
  TOGGLE_REGISTER_POPUP,
  YesNo
} from './types';

export const setTimeUnit = (unit: TimeUnit): CalculatorAction => ({
  type: SET_TIME_UNIT,
  payload: { unit }
});

export const setQuestionAnswer = (
  answer: number | null,
  key: keyof CalculatorState
): CalculatorAction => ({
  type: SET_QUESTION_ANSWER,
  payload: {
    answer,
    key
  }
});

export const setYesNoQuestionAnswer = (
  answer: YesNo,
  key: keyof CalculatorState
): CalculatorAction => ({
  type: SET_YES_NO_QUESTION_ANSWER,
  payload: {
    answer,
    key
  }
});

export const toggleRegisterPopup = (isOpen: boolean): CalculatorAction => ({
  type: TOGGLE_REGISTER_POPUP,
  payload: { isOpen }
});

export const setIsUserRegistered = (
  isRegistered: boolean
): CalculatorAction => ({
  type: SET_IS_USER_REGISTERED,
  payload: { isRegistered }
});

export const clearCalcContents = (): CalculatorAction => ({
  type: CLEAR_CALC_CONTENTS
});

export const calculateTotal =
  (): AppThunk => (dispatch: Dispatch<CalculatorAction>, getState) => {
    const calcState = getState().calculator;

    const {
      questionOne: q1,
      questionTwo: q2,
      questionThree: q3,
      yesNoQuestionOne: yn1,
      yesNoQuestionTwo: yn2,
      yesNoQuestionThree: yn3
    } = calcState;

    const a = q1 === '' ? 0 : q1;
    const b = q2 === '' ? 0 : q2;
    const c = q3 === '' ? 0 : q3;
    let total = 0;
    let PERT = (a + 4 * b + c) / 6;

    total = PERT;

    // if (yesNoQuestionOne === 'yes') total = total + 0.3 * PERT;
    // if (yesNoQuestionTwo === 'yes') total = total - 0.3 * PERT;
    // if (yesNoQuestionThree === 'yes') total = total + 0.2 * PERT;

    total = yn1 === 'yes' ? total + 0.3 * PERT : PERT;
    total = yn2 === 'yes' ? total - 0.3 * PERT : total;
    total = yn3 === 'yes' ? total + 0.2 * PERT : total;

    dispatch({ type: SET_TOTAL, payload: { total } });
  };
