import { useDispatch, useSelector } from 'react-redux';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import { MdAccessTime } from 'react-icons/md';
import { appColors } from '../../theme';
import { getCalculatorState } from '../../store/calculator/selectors';
import { setTimeUnit } from '../../store/calculator/actions';
import { TimeUnit } from '../../store/calculator/types';

const CalcFormHead = () => {
  const { unitOfTime } = useSelector(getCalculatorState);
  const dispatch = useDispatch();

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'start', sm: 'center' }}
      px={2}
      py={1}
      borderRadius={1}
      sx={{
        bgcolor: (theme) => theme.palette.primary.main,
        color: 'white'
      }}>
      <Typography flex={1} fontWeight="600">
        Task
      </Typography>
      <Stack
        mt={{ xs: 2, sm: 0 }}
        direction="row"
        alignItems="center"
        ml={{ xs: 'none', sm: 'auto' }}>
        <Stack direction="row" alignItems="center">
          <MdAccessTime
            fontWeight="bold"
            fontSize="24"
            style={{ marginBottom: '2px', marginRight: '4px' }}
          />
          <Typography fontWeight="600" mr={2}>
            Unit of time:
          </Typography>
        </Stack>
        <Select
          value={unitOfTime}
          MenuProps={{
            sx: { fontSize: '0.9rem' },
            autoFocus: true,
            disableScrollLock: true
          }}
          onChange={(e) => dispatch(setTimeUnit(e.target.value as TimeUnit))}
          sx={{
            flex: 1,
            padding: '0',
            bgcolor: 'white',
            width: '160px',
            py: '0',
            fontSize: '0.9rem',
            border: `1px solid ${appColors.mediumGray}`,
            '&:before': {
              border: `1px solid ${appColors.mediumGray}`
            },
            '&:after': {
              border: `1px solid ${appColors.mediumGray}`
            }
          }}
          size="small">
          <MenuItem value="hours">Hour</MenuItem>
          <MenuItem value="days">Day</MenuItem>
          <MenuItem value="weeks">Week</MenuItem>
        </Select>
      </Stack>
    </Stack>
  );
};

export default CalcFormHead;
