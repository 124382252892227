import { AppThunk } from '..';
import { apiClient } from '../../utils/api';
import errorHandler from '../utils/errorHandler';
import {
  ChatbotAction,
  ChatbotFAQ,
  ChatStatus,
  LOAD_CHATBOT_FAQ,
  SET_CHATBOT_CATEGORY,
  SET_CHATBOT_STATUS
} from './types';

// SYNC Actions
export const loadChatbotFaq = (chatbotFaq: ChatbotFAQ[]): ChatbotAction => ({
  type: LOAD_CHATBOT_FAQ,
  payload: { chatbotFaq }
});

export const setChatbotStatus = (status: ChatStatus): ChatbotAction => ({
  type: SET_CHATBOT_STATUS,
  payload: { status }
});

export const setChatbotCategory = (category: string | null): ChatbotAction => ({
  type: SET_CHATBOT_CATEGORY,
  payload: { category }
});

// ASYNC ACTIONS
export const fetchChatbotFaq = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setChatbotStatus('loading'));

    const response = await apiClient.request<ChatbotFAQ[]>({
      url: 'v1/faqs'
    });

    dispatch(loadChatbotFaq(response.data));

    dispatch(setChatbotStatus('success'));
  } catch (err) {
    dispatch(setChatbotStatus('error'));
    errorHandler(err, dispatch);
  }
};
