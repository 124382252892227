import Fab from '@mui/material/Fab';
import { styled } from '@mui/system';

import { appColors } from '../../theme';

const ChatBotButton = styled(Fab)({
  position: 'fixed',
  bottom: 16,
  zIndex: 20,
  backgroundColor: 'transparent',
  border: `1px solid ${appColors.darkestGray}`,
  boxShadow: 'none',
  transition: 'all 100ms ease-in',

  '& > svg': {
    transition: 'transform 50ms ease-in'
  },

  '&:focus': {
    boxShadow: 'none'
  },

  '&:hover': {
    backgroundColor: 'transparent',
    border: `1px solid ${appColors.calcBtnRed}`,
    color: appColors.calcBtnRed,

    '& > svg': {
      transform: 'scale(1.1)'
    }
  }
});

export default ChatBotButton;
