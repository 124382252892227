import { apiClient } from '../../utils/api';
import { AppThunk } from '../index';
import {
  AppStatus,
  SET_USER_STATUS,
  SET_USER,
  User,
  UserAction
} from './types';

const setUserStatus = (status: AppStatus): UserAction => ({
  type: SET_USER_STATUS,
  payload: { status }
});

const setCurrentUser = (user: User): UserAction => ({
  type: SET_USER,
  payload: { currentUser: user }
});

export const loginUser =
  (email: string, callback?: (err?: string) => void): AppThunk =>
  async (dispatch) => {
    try {
      // check if there's a contact with the given email
      const usersResponse = await apiClient.request<User[]>({
        url: 'v1/contacts'
      });
      const user = usersResponse.data.find((u) => u.email === email);

      // if not found, save it as a new user
      if (!user) {
        const newUserResponse = await apiClient.request<any, any>({
          url: 'v1/contacts',
          method: 'POST',
          data: {
            email,
            jobTitle: '',
            companyName: '',
            location: '',
            status: 'Registered',
            registrationType: 'Digital'
          }
        });

        dispatch(
          setCurrentUser({
            id: newUserResponse.data.id,
            email: newUserResponse.data.email,
            jobTitle: newUserResponse.data.jobTitle
          })
        );
      } else {
        dispatch(
          setCurrentUser({
            id: user.id,
            email: user.email,
            jobTitle: user.jobTitle
          })
        );
      }

      dispatch(setUserStatus('success'));

      if (callback) callback();
    } catch (error) {
      dispatch(setUserStatus('error'));
      //errorHandler(error, dispatch);
      if (callback) callback((error as Error).message);
    }
  };

export const getUserbyEmail =
  (email: string, callback?: (err?: string) => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setUserStatus('loading'));

      const response = await apiClient.request<User[]>({ url: 'v1/contacts' });

      const user = response.data.find((u) => u.email === email);

      if (!user)
        throw new Error('Email is not existing. Register in Save My Access');

      dispatch(
        setCurrentUser({
          id: user.id,
          email: user.email,
          jobTitle: user.jobTitle
        })
      );

      dispatch(setUserStatus('success'));

      if (callback) callback();
    } catch (error) {
      dispatch(setUserStatus('error'));
      //errorHandler(error, dispatch);
      if (callback) callback((error as Error).message);
    }
  };

export const saveUserAccess =
  (user: Omit<User, 'id'>, callback?: (err?: string) => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setUserStatus('loading'));

      const contactResponse = await apiClient.request<User[]>({
        url: 'v1/contacts'
      });

      const emailAlreadyExists = contactResponse.data.some(
        (u) => u.email.toLowerCase() === user.email.toLowerCase()
      );

      if (emailAlreadyExists)
        throw new Error('Email is existing. Login in Returning Tab.');

      const response = await apiClient.request<any, any>({
        url: 'v1/contacts',
        method: 'POST',
        data: {
          ...user,
          companyName: '',
          location: '',
          status: 'Registered',
          registrationType: 'Digital'
        }
      });

      // console.log(response);

      dispatch(
        setCurrentUser({
          id: response.data.id,
          email: user.email,
          jobTitle: user.jobTitle
        })
      );

      dispatch(setUserStatus('success'));

      if (callback) callback();
    } catch (error) {
      dispatch(setUserStatus('error'));
      //errorHandler(error, dispatch);
      if (callback) callback((error as Error).message);
    }
  };
