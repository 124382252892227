import { forwardRef } from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { appColors } from '../../theme';

export interface SelectOptionType {
  id: string;
  label: string;
  value: string;
}

interface OwnSelectProps {
  label?: string;
  options?: SelectOptionType[];
  helperText?: string;
}

type AppSelectProps = OwnSelectProps & Omit<SelectProps, 'label'>;

const AppSelect = forwardRef<HTMLInputElement, AppSelectProps>(
  ({ label, options, helperText, ...rest }, ref) => {
    return (
      <FormControl sx={{ mb: 1 }}>
        {label && (
          <Typography component="label" fontSize={14} htmlFor="email" mb="3px">
            {label}
          </Typography>
        )}
        <Select
          ref={ref}
          {...rest}
          size="small"
          className="calc-select"
          displayEmpty
          sx={{
            bgcolor: appColors.inputBg,
            '& .MuiSvgIcon-root': { color: appColors.darkestGray },
            '&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
              borderColor: appColors.burgundy
            },
            '&:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: appColors.burgundy
            },
            '& fieldset': {
              borderColor: appColors.burgundy
            },
            ...rest.sx
          }}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <Typography
                  component="small"
                  fontWeight={400}
                  color={appColors.lightGray}>
                  {rest.placeholder}
                </Typography>
              );
            }
            return selected as string;
          }}>
          <MenuItem disabled value="">
            <Typography
              component="small"
              fontWeight={400}
              color={appColors.lightGray}>
              {rest.placeholder}
            </Typography>
          </MenuItem>
          {options &&
            options.map((item) => (
              <MenuItem key={item.id} value={item.value} sx={{ width: '100%' }}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
        {rest.error && helperText && (
          <FormHelperText sx={{ m: 0, bgcolor: 'white', color: 'error.main' }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default AppSelect;
