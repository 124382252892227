import { apiClient } from '../../utils/api';
import { AppThunk } from '../index';
import { setSystemSnackbar } from '../system/actions';
import { AppStatus } from '../user/types';
import errorHandler from '../utils/errorHandler';
import {
  FeedbackAction,
  FeedbackAnswer,
  FeedbackListItem,
  FeedbackQuestion,
  FeedbackState,
  LOAD_FEEDBACK_QUESTION_DATA,
  SET_FEEDBACK_STATUS,
  SurveyAnswer,
  SurveyListItem,
  SurveyQuestion,
  UserAnswer
} from './type';

// SYNC ACTIONS
const loadFeedbackQuestionData = (
  data: FeedbackState['data']
): FeedbackAction => ({
  type: LOAD_FEEDBACK_QUESTION_DATA,
  payload: {
    data
  }
});

const setFeedbackStatus = (status: AppStatus): FeedbackAction => ({
  type: SET_FEEDBACK_STATUS,
  payload: {
    status
  }
});

// ASYNC ACTIONS
export const fetchFeedbackQuestionData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setFeedbackStatus('loading'));

    const feedback = await apiClient.request<FeedbackQuestion[]>({
      url: 'v1/feedbacks'
    });

    const survey = await apiClient.request<SurveyQuestion[]>({
      url: 'v1/surveys'
    });

    const data: FeedbackState['data'] = {
      feedbackQuestions: feedback.data,
      surveyQuestions: survey.data
    };

    dispatch(loadFeedbackQuestionData(data));

    dispatch(setFeedbackStatus('success'));
  } catch (err) {
    dispatch(setFeedbackStatus('error'));
    errorHandler(err, dispatch);
  }
};

export const submitFeedbackData =
  (
    feedbackAnswers: FeedbackAnswer[],
    userAnswer: UserAnswer,
    surveyAnswers: SurveyAnswer[],
    callback?: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setFeedbackStatus('loading'));
      const { currentUser } = getState().user;

      if (!currentUser)
        throw new Error('Not registered. Register first in Save My Access');

      const { id, email } = currentUser;

      const feedbackListItem: Omit<FeedbackListItem, 'id'> = {
        contactId: id,
        email,
        surveyAnswers: feedbackAnswers
      };

      const surveyListItem: Omit<SurveyListItem, 'id'> = {
        contactId: id,
        email,
        surveyAnswers
      };

      await apiClient.request({
        url: 'v1/contact-feedback',
        method: 'POST',
        data: feedbackListItem
      });

      await apiClient.request({
        url: 'v1/contact-survey',
        method: 'POST',
        data: surveyListItem
      });

      await apiClient.request({
        url: `v1/contacts/${id}`,
        method: 'PUT',
        data: userAnswer
      });

      dispatch(setFeedbackStatus('success'));

      dispatch(
        setSystemSnackbar({
          type: 'success',
          message: 'Your feedback was successfully submitted!',
          open: true
        })
      );

      if (callback) callback();

      setTimeout(() => {
        dispatch(setSystemSnackbar(null));
      }, 3000);
    } catch (err) {
      dispatch(setFeedbackStatus('error'));
      errorHandler(err, dispatch);
    }
  };
