import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { styled } from '@mui/system';
import { appColors, appSizes } from '../../theme';

export const ChatContainer = styled(Stack)({
  width: appSizes.chatboxWidth,
  borderRadius: 0,
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  overflow: 'hidden'
});

export const ChatBorderedMessage = styled(Stack)({
  border: `1px solid ${appColors.burgundy}`,
  borderRadius: 8,
  flexDirection: 'row',
  width: '80%',
  alignItems: 'center',
  padding: '8px 12px',
  fontSize: '12px',
  color: appColors.mediumGray,
  maxWidth: '210px',
  wordBreak: 'break-word',

  '&.answer': {
    borderBottomLeftRadius: 0,
    width: 'max-content'
  },

  '&.question': {
    borderBottomRightRadius: 0,
    width: 'max-content',
    marginLeft: 'auto',
    color: '#fff',
    backgroundColor: appColors.primary,
    borderColor: appColors.primary
  }
});

export const AnimatedBox = styled(Stack)({
  width: '100%',
  flexShrink: 0,
  transform: 'translateX(45%)',
  transition: 'transform 200ms ease-out',
  backgroundColor: 'white',
  zIndex: 3,

  '&.slide-left': {
    transform: 'translateX(-100%)'
  }
});

export const FAQAccordionHead = styled(Button)({
  textAlign: 'left',
  justifyContent: 'start',
  backgroundColor: appColors.offWhite,

  '& > .MuiButton-endIcon': {
    marginLeft: 'auto',
    transition: 'transform 200ms'
  },

  '&.open > .MuiButton-endIcon': {
    transform: 'rotate(180deg)'
  }
});
