import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { appColors } from '../../theme';
import { HeaderLogo } from './Logo';

const Footer = () => {
  return (
    <Stack
      component="footer"
      id="ramsay-footer"
      bgcolor={appColors.primary}
      color="white"
      py={{ xs: 6, sm: 16 }}
      height={{ xs: 'auto', sm: 280 }}
      justifyContent="center"
      alignItems="center">
      <Stack
        width="70%"
        flexDirection={{ xs: 'column', sm: 'row' }}
        gap={{ xs: 3, sm: 0 }}
        alignItems="center"
        justifyContent="space-between">
        <Button
          className="rounded-btn no-hover"
          LinkComponent={Link}
          href="https://calendly.com/ramsayprojects/"
          target="_blank"
          rel="noopener"
          sx={{ color: 'white' }}>
          Free Consultation
        </Button>
        <Stack
          gap={2}
          width="270px"
          justifyContent="center"
          alignItems="center"
          textAlign="justify">
          <Typography fontWeight="bold" mt={2}>
            ©2022 RAMSAY PROJECTS INC.
          </Typography>
          <Typography fontSize={10}>
            The tool, and all other content provided on this website is strictly
            for informational purposes and does not amount to professional
            advice. By using this calculator, you acknowledge that Ramsay
            Projects Inc. is not responsible or liable for use of output
            calculations.
          </Typography>
          <Typography fontSize={10}>
            Users of this website acknowledge that certain statistical
            information may be compiled and used on an anonymous basis to
            monitor traffic to the website and feedback on future improvements
            to the calculator.
          </Typography>
        </Stack>
        <Link
          sx={{
            width: 170,
            height: 50,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            bgcolor: 'white',
            padding: 3,
            borderRadius: 1,
            boxSizing: 'border-box'
          }}
          href="https://www.ramsayprojects.com/"
          aria-label="ramsayprojects.com"
          target="_blank"
          rel="noreferrer">
          <HeaderLogo />
        </Link>
      </Stack>
    </Stack>
  );
};

export default Footer;
