export type TimeUnit = 'hours' | 'days' | 'weeks';
export type YesNo = 'yes' | 'no' | null;

export interface CalculatorState {
  unitOfTime: TimeUnit | null;
  isRegisterPopupOpen: boolean;
  isUserRegistered: boolean;
  questionOne: number | '';
  questionTwo: number | '';
  questionThree: number | '';
  yesNoQuestionOne: YesNo;
  yesNoQuestionTwo: YesNo;
  yesNoQuestionThree: YesNo;
  total: number | '';
}

// ACTION TYPES
export const SET_TIME_UNIT = 'calculator/timeUnit/set';
export const SET_QUESTION_ANSWER = 'calculator/question/set/answer';
export const SET_YES_NO_QUESTION_ANSWER =
  'calculator/yes_no_question/set/answer';
export const TOGGLE_REGISTER_POPUP = 'calculator/register_popup_toggle';
export const SET_IS_USER_REGISTERED = 'calculator/is_user_registered/set';
export const CLEAR_CALC_CONTENTS = 'calculator/clear';
export const SET_TOTAL = 'calculator/total/set';

interface SetTimeUnitAction {
  type: typeof SET_TIME_UNIT;
  payload: { unit: TimeUnit };
}

interface SetTotalAction {
  type: typeof SET_TOTAL;
  payload: { total: number };
}

interface SetQuestionAnswerAction {
  type: typeof SET_QUESTION_ANSWER;
  payload: { answer: number | null; key: keyof CalculatorState };
}

interface SetYesNoQuestionAnswerAction {
  type: typeof SET_YES_NO_QUESTION_ANSWER;
  payload: { answer: YesNo; key: keyof CalculatorState };
}

interface ToggleRegisterPopupAction {
  type: typeof TOGGLE_REGISTER_POPUP;
  payload: { isOpen: boolean };
}

interface SetIsUserRegisteredAction {
  type: typeof SET_IS_USER_REGISTERED;
  payload: { isRegistered: boolean };
}

interface ClearCalcAction {
  type: typeof CLEAR_CALC_CONTENTS;
}

export type CalculatorAction =
  | SetTimeUnitAction
  | SetQuestionAnswerAction
  | SetYesNoQuestionAnswerAction
  | ToggleRegisterPopupAction
  | SetIsUserRegisteredAction
  | ClearCalcAction
  | SetTotalAction;
