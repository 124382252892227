import { useState, MouseEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AiFillWechat } from 'react-icons/ai';
import useIsMobile from '../../hooks/useIsMobile';
import { appSizes } from '../../theme';

import ChatBotButton from './ChatBotButton';
import ChatBox from './ChatBox';
import { fetchChatbotFaq } from '../../store/chatbot/actions';
import useMeasure from '../../hooks/useMeasure';

const Chatbot = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isMobile = useIsMobile();

  const dispatch = useDispatch();

  const { x } = useMeasure('app-calculator');

  useEffect(() => {
    dispatch(fetchChatbotFaq());
  }, [dispatch]);

  const rightBounds = x + 20;
  const rightPos = isMobile ? appSizes.chatbotPosMobile : rightBounds;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'ramsay-chatbot-chatbox' : undefined;

  return (
    <>
      <ChatBotButton
        disableRipple
        aria-describedby={id}
        onClick={handleClick}
        sx={{ right: rightPos }}>
        <AiFillWechat fontSize={38} />
      </ChatBotButton>
      <ChatBox open={open} onClose={handleClose} rightOfChatButton={rightPos} />
    </>
  );
};

export default Chatbot;
