import { styled } from '@mui/system';

import logo from '../../assets/images/logo-no-bg.png';
import headerLogoImg from '../../assets/images/ramsay-header-logo-no-bg.png';

const StyledHeaderLogo = styled('img')({
  objectFit: 'cover'
});

const StyledLogo = styled('img')({
  width: '48px',
  height: '48px',
  marginTop: '10px',
  flexGrow: 0
});

const Logo = () => <StyledLogo src={logo} alt="Ramsay logo" />;

export const HeaderLogo = () => (
  <StyledHeaderLogo src={headerLogoImg} alt="Ramsay Sizing Calculator" />
);

export default Logo;
