import {
  FeedbackAction,
  FeedbackState,
  LOAD_FEEDBACK_QUESTION_DATA,
  SET_FEEDBACK_STATUS
} from './type';

const initialState: FeedbackState = {
  status: 'idle',
  error: null,
  data: { feedbackQuestions: [], surveyQuestions: [] }
};

const feedbackReducer = (
  state = initialState,
  action: FeedbackAction
): FeedbackState => {
  switch (action.type) {
    case SET_FEEDBACK_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_FEEDBACK_QUESTION_DATA:
      return { ...state, data: action.payload.data };
    default:
      return state;
  }
};

export default feedbackReducer;
