import { useDispatch } from 'react-redux';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';

import { useState, useReducer } from 'react';
import { appColors, appSizes } from '../../theme';
import ChatBoxHead from './ChatBoxHead';
import ChatCategories from './ChatCategories';
import { ChatBorderedMessage, ChatContainer } from './ChatUiLib';
import useIsMobile from '../../hooks/useIsMobile';
import { setChatbotCategory } from '../../store/chatbot/actions';
import FAQList from './FAQList';

interface ChatBoxProps {
  open: boolean;
  onClose: () => void;
  rightOfChatButton: number;
}

const ChatBox = ({ open, onClose, rightOfChatButton }: ChatBoxProps) => {
  const isMobile = useIsMobile();
  const rightPosition = isMobile
    ? appSizes.chatbotPosMobile
    : rightOfChatButton + 28 - 0.5 * appSizes.chatboxWidth;

  const [questionClass, setQuestionClass] = useState('');

  const [openedFaqs, faqDispatch] = useReducer(
    (state: string[], action: { type: string; payload: string }) => {
      switch (action.type) {
        case 'toggle':
          return state.includes(action.payload)
            ? state.filter((s) => s !== action.payload)
            : [...state, action.payload];
        case 'prevent-close-all':
          return [action.payload];
        case 'single':
          return state.includes(action.payload) ? [] : [action.payload];
        case 'reset':
          return [];
        default:
          return state;
      }
    },
    []
  );

  const toggleFAQ = (fId: string) =>
    faqDispatch({ type: 'single', payload: fId });

  const dispatch = useDispatch();

  const handleCategoryClick = (category: string) => {
    setQuestionClass('slide-left');

    dispatch(setChatbotCategory(category));
  };

  const handleBack = () => {
    setQuestionClass('');
    faqDispatch({ type: 'reset', payload: '' });
    dispatch(setChatbotCategory(null));
  };

  const handleClose = () => {
    setQuestionClass('');
    faqDispatch({ type: 'reset', payload: '' });
    dispatch(setChatbotCategory(null));
    onClose();
  };

  return (
    <Fade in={open}>
      <ChatContainer
        position="fixed"
        bottom={80}
        right={rightPosition}
        zIndex={20}>
        <ChatBoxHead onClose={handleClose} />
        <Stack
          direction="row"
          border={1}
          bgcolor="white"
          borderColor={appColors.primary}
          borderTop={0}>
          <Stack
            px={3}
            pt={6}
            pb={3}
            gap={1}
            width="100%"
            flexShrink={0}
            flexGrow={0}>
            <ChatBorderedMessage>
              Hi! I'm Chatty, your Support Bot.
            </ChatBorderedMessage>
            <ChatBorderedMessage mb={5}>
              Select from the menu below
            </ChatBorderedMessage>
            <ChatCategories onItemClick={handleCategoryClick} />
          </Stack>
          <FAQList
            openedFaqs={openedFaqs}
            onToggleFAQ={toggleFAQ}
            onBackClick={handleBack}
            questionClass={questionClass}
          />
        </Stack>
      </ChatContainer>
    </Fade>
  );
};

export default ChatBox;
