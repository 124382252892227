import { styled } from '@mui/system';

import sectionImage from '../../assets/images/calculator-section-bg.png';

const CalculatorSectionBackground = styled('div')({
  width: '100%',
  // minHeight: '900px',
  padding: '55px 0', // previously 80px 0 - 06.10.2022
  position: 'relative',
  zIndex: 1,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:before': {
    width: '100%',
    height: '100%',
    display: 'block',
    content: '""',
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'white',
    backgroundPosition: 'center top',
    backgroundImage: `url(${sectionImage})`,
    top: 25,
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'scale(1.1)' /* this will do the trick */,
    zIndex: -1
  }
});

export default CalculatorSectionBackground;
