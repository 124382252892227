import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { AnimatedBox } from './ChatUiLib';
import FAQAccordion from './FAQAccordion';
import { useSelector } from 'react-redux';
import { getQuestionsByCurrentCategory } from '../../store/chatbot/selectors';
import { ChevronLeft } from '@mui/icons-material';

interface FAQListProps {
  onBackClick: () => void;
  questionClass: string;
  openedFaqs: string[];
  onToggleFAQ: (qId: string) => void;
}

const FAQList = (props: FAQListProps) => {
  const questions = useSelector(getQuestionsByCurrentCategory);

  const { questionClass, onToggleFAQ, openedFaqs, onBackClick } = props;
  return (
    <AnimatedBox pt={4} pb={3} className={questionClass}>
      <Stack px={2} gap={1} maxHeight={260} overflow="auto">
        <Button
          onClick={onBackClick}
          startIcon={<ChevronLeft />}
          sx={{ justifyContent: 'start', width: 'max-content', color: '#333' }}
          variant="text">
          Menu
        </Button>
        {questions.map((q) => (
          <FAQAccordion
            key={q.id}
            faq={q}
            open={openedFaqs.includes(q.id)}
            onToggle={onToggleFAQ}
          />
        ))}
      </Stack>
    </AnimatedBox>
  );
};

export default FAQList;
