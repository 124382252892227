import AppModal from '../common/AppModal';
import ReturningForm from './ReturningForm';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/user/selector';
import { Stack } from '@mui/material';

interface RegisterPopupProps {
  onClose: () => void;
}

const RegisterPopup = ({ onClose }: RegisterPopupProps) => {
  const { status } = useSelector(selectUserState);

  return (
    <AppModal onClose={onClose} noScrollLock loading={status === 'loading'}>
      <Stack mt={-2} px={3}>
        <ReturningForm />
      </Stack>
    </AppModal>
  );
};

export default RegisterPopup;
