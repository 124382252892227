import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const CalcSectionDescription = () => {
  return (
    <Stack gap={3} mr={2} mb={{ xs: 3, sm: 4 }} mt={1}>
      <Typography mt={2} fontSize={12}>
        Use this simple calculator to help build a high-level schedule. There
        are six (6) questions to help you estimate at either a task or
        deliverable level. Answering the questions to the best of your knowledge
        will help you develop an initial schedule for your deliverable or
        project.
      </Typography>
      <Typography fontSize={12}>
        For more information on how to use the calculator as an individual
        resource, team lead or project manager, ask ChattyBot below!
      </Typography>
      <Typography fontSize={12}>
        This calculator is not intended to be an exact science since every
        circumstance is different. As work unfolds and teams have a better
        understanding of requirements, these estimates can be refined for
        greater accuracy in your project plan.
      </Typography>
    </Stack>
  );
};

export default CalcSectionDescription;
