import { ReactNode } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';

import {
  BsFillEmojiHeartEyesFill as VerySatisfied,
  BsEmojiSmileFill as Satisfied,
  BsEmojiNeutralFill as Neutral,
  BsEmojiFrownFill as Unsatisfied,
  BsFillEmojiDizzyFill as VeryUnsatisfied
} from 'react-icons/bs';
import { appColors } from '../../theme';
import { FeedbackAnswer, FeedbackAnswerType } from '../../store/feedback/type';

const size = 32;

interface EmojiItemProps {
  icon: ReactNode;
  label: string;
  value?: FeedbackAnswerType;
  selected?: boolean;
  onClick?: (value: FeedbackAnswerType) => void;
}

const EmojiItem = (props: EmojiItemProps) => {
  const { icon, label, value, selected, onClick } = props;

  const handleClick = () => {
    if (onClick) onClick(value as FeedbackAnswerType);
  };

  return (
    <Stack
      onClick={handleClick}
      alignItems="center"
      width={67}
      gap={1}
      color={selected ? appColors.primary : appColors.burgundy}
      sx={{
        cursor: 'pointer',
        transition: 'color 150ms ease-in',
        '&:hover': { color: selected ? appColors.primary : appColors.titleGray }
      }}>
      {icon}
      <Typography color="inherit" fontSize={9} component="span">
        {label}
      </Typography>
    </Stack>
  );
};

interface FeedbackEmojiPickerProps {
  question: string;
  questionId: string;
  onClick?: (value: FeedbackAnswer) => void;
  currentValue?: FeedbackAnswerType;
  error?: boolean;
}

const FeedbackEmojiPicker = (props: FeedbackEmojiPickerProps) => {
  const { question, onClick, currentValue, questionId, error } = props;

  const handleClick = (answer: FeedbackAnswerType) => {
    if (onClick) onClick({ id: questionId, question, answer });
  };
  return (
    <>
      <Typography fontWeight="bold">{question}</Typography>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={2}>
        <EmojiItem
          onClick={handleClick}
          selected={currentValue === 'Very Satisfied'}
          value="Very Satisfied"
          icon={<VerySatisfied fontSize={size} />}
          label="Very Satisfied"
        />
        <EmojiItem
          selected={currentValue === 'Satisfied'}
          onClick={handleClick}
          value="Satisfied"
          icon={<Satisfied fontSize={size} />}
          label="Satisfied"
        />
        <EmojiItem
          selected={currentValue === 'Neutral'}
          onClick={handleClick}
          value="Neutral"
          icon={<Neutral fontSize={size} />}
          label="Neutral"
        />
        <EmojiItem
          selected={currentValue === 'Unsatisfied'}
          onClick={handleClick}
          value="Unsatisfied"
          icon={<Unsatisfied fontSize={size} />}
          label="Unsatisfied"
        />
        <EmojiItem
          selected={currentValue === 'Very Unsatisfied'}
          onClick={handleClick}
          value="Very Unsatisfied"
          icon={<VeryUnsatisfied fontSize={size} />}
          label="Very Unsatisfied"
        />
      </Stack>
      {error && (
        <FormHelperText sx={{ m: 0, bgcolor: 'white', color: 'error.main' }}>
          This field is required.
        </FormHelperText>
      )}
    </>
  );
};

export default FeedbackEmojiPicker;
