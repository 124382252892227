import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { SxProps } from '@mui/system';

import AppSelect from '../common/AppSelect';
import FeedbackEmojiPicker from './FeedbackEmojiPicker';
import { contactLocationOptions } from '../../utils/constants';
import {
  getTopFeedbackQuestions,
  getTopSurveyQuestions
} from '../../store/feedback/selectors';

import { mapToOptions } from '../../utils/helpers';
import {
  FeedbackAnswer,
  SurveyAnswer,
  UserAnswer
} from '../../store/feedback/type';
import { submitFeedbackData } from '../../store/feedback/actions';

const helperTextStyle: SxProps = {
  m: 0,
  bgcolor: 'white'
};

interface FeedbackFormProps {
  closeCallback: () => void;
}

const FeedbackForm = (props: FeedbackFormProps) => {
  const { closeCallback } = props;
  const feedbackQuestions = useSelector(getTopFeedbackQuestions);
  const surveyQuestions = useSelector(getTopSurveyQuestions);
  const dispatch = useDispatch();

  const [userAnswer, setUserAnswer] = useState<UserAnswer>({
    name: '',
    companyName: '',
    location: '',
    jobTitle: ''
  });

  const [feedbackAnswers, setFeedbackAnswers] = useState<FeedbackAnswer[]>([]);
  const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswer[]>([]);

  const [userErrors, setUserErrors] = useState({
    name: '',
    companyName: '',
    location: '',
    jobTitle: ''
  });

  const [surveyErrors, setSurveyErrors] = useState<string[]>([]);
  const [feedbackErrors, setFeedbackErrors] = useState<string[]>([]);

  const [hasConsent, setHasConsent] = useState(false);

  const surveyAnswersId = surveyAnswers.map((a) => a.id);
  const feedbackAnswersId = feedbackAnswers.map((a) => a.id);

  const hasSurveyError = (id: string) => surveyErrors.includes(id);
  const hasFeedbackError = (id: string) => feedbackErrors.includes(id);

  const isFormValid =
    feedbackAnswers.length === feedbackQuestions.length &&
    surveyAnswers.length === surveyQuestions.length &&
    Object.values(userAnswer).every((i) => i !== '') &&
    hasConsent;

  const handleFeedbackAnswerClick = (answer: FeedbackAnswer) => {
    setFeedbackErrors([]);
    if (feedbackAnswers.some((i) => i.id === answer.id)) {
      const _feedbackAnswers = feedbackAnswers.map((f) =>
        f.id === answer.id ? { ...answer } : f
      );
      setFeedbackAnswers(_feedbackAnswers);
    } else setFeedbackAnswers((v) => [...v, answer]);
  };

  const getCurrentFeedbackValue = (id: string) =>
    feedbackAnswers.find((i) => i.id === id);

  const handleUserChange = (e: any) => {
    setUserErrors({ name: '', companyName: '', location: '', jobTitle: '' });
    setUserAnswer({ ...userAnswer, [e.target.name]: e.target.value });
  };

  const handleSurveyAnswerChange =
    (question: string, id: string) => (e: any) => {
      setSurveyErrors([]);
      if (surveyAnswers.some((i) => i.id === id)) {
        const _surveyAnswers =
          e.target.value !== ''
            ? surveyAnswers.map((f) =>
                f.id === id ? { id, question, answer: e.target.value } : f
              )
            : surveyAnswers.filter((f) => f.id !== id);

        setSurveyAnswers(_surveyAnswers);
      } else if (e.target.value !== '')
        setSurveyAnswers((v) => [
          ...v,
          { id, question, answer: e.target.value }
        ]);
    };

  const getCurrentSurveyAnswer = (id: string) =>
    surveyAnswers.find((a) => a.id === id)?.answer;

  const handleSubmit = () => {
    const { name, companyName, location, jobTitle } = userAnswer;

    setUserErrors({
      name: name ? '' : 'Name is required.',
      companyName: companyName ? '' : 'Company is required.',
      location: location ? '' : 'Location is required.',
      jobTitle: jobTitle ? '' : 'Job Title is required.'
    });

    setSurveyErrors(
      surveyQuestions
        .filter((q) => !surveyAnswersId.includes(q.id))
        .map((i) => i.id)
    );

    setFeedbackErrors(
      feedbackQuestions
        .filter((q) => !feedbackAnswersId.includes(q.id))
        .map((i) => i.id)
    );

    if (isFormValid) {
      dispatch(
        submitFeedbackData(
          feedbackAnswers,
          userAnswer,
          surveyAnswers,
          closeCallback
        )
      );
    }
  };

  const getInputClass = (key: keyof typeof userErrors) => {
    return `calc-field ${Boolean(userErrors[key]) && 'error'}`;
  };

  return (
    <Stack component="form" pb={1} px={4}>
      <Stack gap={3}>
        {feedbackQuestions.map((item) => (
          <FeedbackEmojiPicker
            currentValue={getCurrentFeedbackValue(item.id)?.answer}
            onClick={handleFeedbackAnswerClick}
            key={item.id}
            questionId={item.id}
            question={item.question}
            error={hasFeedbackError(item.id)}
          />
        ))}
      </Stack>
      <Stack gap={3} mt={3}>
        <TextField
          value={userAnswer.name}
          name="name"
          onChange={handleUserChange}
          size="small"
          placeholder="Name"
          className={getInputClass('name')}
          error={Boolean(userErrors.name)}
          helperText={userErrors.name}
          FormHelperTextProps={{ sx: helperTextStyle }}
        />
        <TextField
          value={userAnswer.jobTitle}
          name="jobTitle"
          onChange={handleUserChange}
          size="small"
          placeholder="Job Title"
          className={getInputClass('jobTitle')}
          error={Boolean(userErrors.jobTitle)}
          helperText={userErrors.jobTitle}
          FormHelperTextProps={{ sx: helperTextStyle }}
        />
        <TextField
          value={userAnswer.companyName}
          name="companyName"
          onChange={handleUserChange}
          size="small"
          placeholder="Company Name"
          className={getInputClass('companyName')}
          error={Boolean(userErrors.companyName)}
          helperText={userErrors.companyName}
          FormHelperTextProps={{ sx: helperTextStyle }}
        />
        <AppSelect
          value={userAnswer.location}
          name="location"
          onChange={handleUserChange}
          placeholder="Location"
          options={contactLocationOptions}
          error={Boolean(userErrors.location)}
          helperText={userErrors.location}
        />
        {surveyQuestions.map((item) => (
          <Stack gap={1} key={item.id}>
            <Typography fontWeight="bold">{item.question}</Typography>
            {item.answerType === 'Text Box' ? (
              <TextField
                value={getCurrentSurveyAnswer(item.id)}
                onChange={handleSurveyAnswerChange(item.question, item.id)}
                size="small"
                multiline
                rows={4}
                placeholder="Enter answer here"
                className={`calc-field ${hasSurveyError(item.id) && 'error'}`}
                error={hasSurveyError(item.id)}
                helperText="This field is required."
                FormHelperTextProps={{
                  sx: {
                    ...helperTextStyle,
                    display: hasSurveyError(item.id) ? 'block' : 'none'
                  }
                }}
              />
            ) : (
              <AppSelect
                value={getCurrentSurveyAnswer(item.id) || ''}
                onChange={handleSurveyAnswerChange(item.question, item.id)}
                placeholder="Select an option"
                options={mapToOptions(item.dropDownItems)}
                className={`calc-field ${hasSurveyError(item.id) && 'error'}`}
                error={hasSurveyError(item.id)}
                helperText="This field is required."
              />
            )}
          </Stack>
        ))}

        <FormControlLabel
          sx={{ mx: 3, alignItems: 'flex-start' }}
          control={
            <Checkbox
              checked={hasConsent}
              onChange={(e) => setHasConsent(e.target.checked)}
              sx={{ mt: -1 }}
            />
          }
          label="Thank you for your feedback. Please check the box and we will follow up by email."
        />
      </Stack>
      <Stack my={4} pt={1} alignItems="center">
        <Button
          // disabled={!isFormValid}
          onClick={handleSubmit}
          sx={{ width: 220 }}
          className="calc-btn primary">
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default FeedbackForm;
