import React from 'react';
import Stack from '@mui/material/Stack';

import CalculatorSectionBackground from './CalculatorSectionBackground';
import CalcSectionTitle from './CalcSectionTitle';
import CalcSectionDescription from './CalcSectionDescription';
import CalculatorForm from './CalculatorForm';

const CalculatorSection = () => {
  return (
    <CalculatorSectionBackground>
      <Stack
        px={{ xs: 3, sm: 6, md: 18 }}
        py={{ xs: 2, sm: '30px' }}
        direction="row"
        gap={2}
        width={{ xs: '90%', md: '70%' }}
        id="app-calculator"
        position="relative">
        <Stack
          borderRadius={3}
          bgcolor="white"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            opacity: 0.8,
            zIndex: -1
          }}
        />
        <Stack>
          <CalcSectionTitle />
          <CalcSectionDescription />
          <CalculatorForm />
        </Stack>
      </Stack>
    </CalculatorSectionBackground>
  );
};

export default CalculatorSection;
