import { ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { ChatbotFAQ } from '../../store/chatbot/types';
import { FAQAccordionHead } from './ChatUiLib';
import { Typography } from '@mui/material';

interface FAQAccordionProps {
  open: boolean;
  faq: ChatbotFAQ;
  onToggle: (faqId: string) => void;
}

const FAQAccordion = ({ faq, open, onToggle }: FAQAccordionProps) => {
  const { question, answer, id } = faq;

  const classes = open ? 'open' : '';

  return (
    <Box flex={1}>
      <FAQAccordionHead
        className={classes}
        fullWidth
        variant="text"
        endIcon={<ExpandMore />}
        onClick={() => onToggle(id)}>
        {question}
      </FAQAccordionHead>
      <Collapse in={open}>
        <Box p={2} mt={1} border={1} borderColor="#eee" borderRadius={1}>
          <Typography fontSize={14} color="primary" mb={1}>
            Answer
          </Typography>
          <Typography fontSize={14} sx={{ color: '#333' }}>
            {answer}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

export default FAQAccordion;
