import { AppState } from '../index';
import { uniq } from 'lodash';

export const getChatbotState = (state: AppState) => state.chatbot;

export const getChatCategories = (state: AppState) =>
  uniq(state.chatbot.data.map((c) => c.category));

export const getQuestionsByCurrentCategory = (state: AppState) =>
  state.chatbot.data.filter(
    (i) => i.category === state.chatbot.currentCategory
  );
