import { UserAction, UserState, SET_USER_STATUS, SET_USER } from './types';

const initialState: UserState = {
  currentUser: null,
  status: 'idle',
  error: null
};

const userReducer = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case SET_USER:
      return { ...state, currentUser: action.payload.currentUser };
    case SET_USER_STATUS:
      return { ...state, status: action.payload.status };
    default:
      return state;
  }
};

export default userReducer;
