import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

interface YesNoQuestionProps {
  question: string;
  name: string;
  show: boolean;
  onChange: RadioGroupProps['onChange'];
}

const YesNoQuestion = (props: YesNoQuestionProps) => {
  const { question, name, show, onChange } = props;
  if (!show) return null;
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography>{question}</Typography>
      </Grid>
      <Grid item xs={4} ml="auto" alignSelf="center">
        <RadioGroup
          row
          aria-label={question}
          defaultValue=""
          name={name}
          onChange={onChange}>
          <FormControlLabel
            value="yes"
            sx={{ mr: 4 }}
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default YesNoQuestion;
