export type AppStatus = 'idle' | 'loading' | 'success' | 'error';

export interface User {
  email: string;
  jobTitle: string;
  id: string;
}

export interface ContactUser {
  id: string;
  email: string;
  jobTitle: string;
  companyName: string;
  location: string;
  status: string;
  registrationType: string;
  dateUpdated: string;
  dateCreated: string;
  dateRegistered: string;
}

export interface UserState {
  currentUser: User | null;
  status: AppStatus;
  error: string | null;
}

export const SET_USER = 'set/user/current';
export const SET_USER_STATUS = 'set/user/status';

interface SetUserStatusAction {
  type: typeof SET_USER_STATUS;
  payload: { status: AppStatus };
}

interface SetUserAction {
  type: typeof SET_USER;
  payload: { currentUser: User | null };
}

export type UserAction = SetUserAction | SetUserStatusAction;
