import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CalculatorSection from './components/calculatorSection';
import Chatbot from './components/chatbot';
import AppAlert from './components/common/AppAlert';
import AppLoadingIndicator from './components/common/AppLoadingIndicator';
import Footer from './components/common/Footer';
import { fetchFeedbackQuestionData } from './store/feedback/actions';
import { getFeedbackState } from './store/feedback/selectors';
import { selectSnackbarState } from './store/system/actions';

const App = () => {
  const snackbar = useSelector(selectSnackbarState);
  const { status } = useSelector(getFeedbackState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFeedbackQuestionData());
  }, [dispatch]);

  return (
    <>
      <AppLoadingIndicator show={status === 'loading'} />
      <Stack position="relative">
        <CalculatorSection />
        <Footer />
        <Chatbot />
      </Stack>
      <AppAlert
        open={snackbar?.open}
        message={snackbar?.message}
        type={snackbar?.type}
      />
    </>
  );
};

export default App;
