import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

import { appColors } from '../../theme';
import { HeaderLogo } from '../common/Logo';

const CalcSectionTitle = () => {
  return (
    <Stack
      flexDirection="row"
      borderBottom={`3px solid ${appColors.mediumGray}`}
      width="100%">
      <Typography
        fontSize={{ xs: 28, sm: 47 }}
        fontWeight="bold"
        color="primary">
        Sizing Calculator
      </Typography>
      <Link
        sx={{
          width: 170,
          height: 50,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ml: 'auto',
          mt: 1
        }}
        href="https://www.ramsayprojects.com/"
        aria-label="ramsayprojects.com"
        target="_blank"
        rel="noreferrer">
        <HeaderLogo />
      </Link>
    </Stack>
  );
};

export default CalcSectionTitle;
