import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { appColors } from '../../theme';

import chattyIcon from '../../assets/images/chatty-icon-grey.png';
import { styled } from '@mui/system';

const ChattyImage = styled('img')({
  width: '60px',
  height: '60px',
  border: `1px solid ${appColors.primary}`,
  position: 'absolute',
  top: '32%',
  zIndex: 10,
  borderRadius: '50%'
});

interface ChatBoxHeadProps {
  onClose: () => void;
}

const ChatBoxHead = ({ onClose }: ChatBoxHeadProps) => {
  return (
    <Stack
      py={1}
      pr={1}
      pl={3}
      sx={{ bgcolor: appColors.primary }}
      direction="row"
      alignItems="center"
      position="relative">
      <ChattyImage src={chattyIcon} alt="Ramsay Chatty Icon" />
      <Stack ml={10} color="white" gap={0} alignSelf="end">
        <Typography fontSize={14} fontWeight="bold">
          Chatty
        </Typography>
        <Typography lineHeight={0.8} fontSize={12} fontWeight="500">
          Support Bot
        </Typography>
      </Stack>
      <IconButton
        sx={{ ml: 'auto', color: 'white' }}
        aria-label="close chatbot"
        onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

export default ChatBoxHead;
