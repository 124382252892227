import {
  ChatbotAction,
  ChatbotState,
  LOAD_CHATBOT_FAQ,
  SET_CHATBOT_CATEGORY,
  SET_CHATBOT_STATUS
} from './types';

const initialState: ChatbotState = {
  data: [],
  status: 'idle',
  error: null,
  currentCategory: null
};

const chatbotReducer = (
  state = initialState,
  action: ChatbotAction
): ChatbotState => {
  switch (action.type) {
    case SET_CHATBOT_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_CHATBOT_FAQ:
      return { ...state, data: action.payload.chatbotFaq, status: 'idle' };
    case SET_CHATBOT_CATEGORY:
      return { ...state, currentCategory: action.payload.category };
    default:
      return state;
  }
};

export default chatbotReducer;
