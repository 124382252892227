import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { getChatCategories } from '../../store/chatbot/selectors';

interface ChatCategoriesProps {
  onItemClick: (category: string) => void;
}

const ChatCategories = ({ onItemClick }: ChatCategoriesProps) => {
  const categories = useSelector(getChatCategories);

  return (
    <Stack
      mt="auto"
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      gap={1}>
      {categories.map((item) => (
        <Chip
          className="chat-chip no-hover"
          key={item}
          label={item}
          onClick={() => onItemClick(item)}
          clickable
          color="primary"
          variant="outlined"
        />
      ))}
    </Stack>
  );
};

export default ChatCategories;
