import { AppState } from '../index';
import { orderBy, take } from 'lodash';

export const getFeedbackState = (state: AppState) => state.feedback;

export const getTopFeedbackQuestions = (state: AppState) =>
  take(
    orderBy(state.feedback.data.feedbackQuestions, ['dateCreated'], ['desc']),
    5
  );

export const getTopSurveyQuestions = (state: AppState) =>
  take(
    orderBy(state.feedback.data.surveyQuestions, ['dateCreated'], ['desc']),
    5
  );
