import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ScrollToBottom from 'react-scroll-to-bottom';
import styled from '@emotion/styled';

import { FaEquals } from 'react-icons/fa';
import { appColors } from '../../theme';
import YesNoQuestion from './YesNoQuestion';
import { yesNoQuestions } from '../../constants';
import CalcFormHead from './CalcFormHead';
import RegisterPopup from '../registerPopup';
import { getCalculatorState } from '../../store/calculator/selectors';
import {
  calculateTotal,
  clearCalcContents,
  setQuestionAnswer,
  setYesNoQuestionAnswer,
  toggleRegisterPopup
} from '../../store/calculator/actions';
import { CalculatorState, YesNo } from '../../store/calculator/types';
import FeedbackModal from '../feedback/FeedbackModal';

const ScrollToBottomStyled = styled(ScrollToBottom)({
  height: 270,
  marginTop: '24px',
  scrollBehavior: 'smooth',
  borderBottom: `3px solid ${appColors.mediumGray}`,

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '0 16px 16px'
  },

  '& > button': {
    display: 'none'
  }
});

const CalculatorForm = () => {
  const dispatch = useDispatch();
  const calcState = useSelector(getCalculatorState);

  const [feedbackShown, setFeedbackShown] = useState(false);
  const calcBtnRef = useRef<HTMLDivElement | null>(null);

  const {
    isUserRegistered,
    isRegisterPopupOpen,
    unitOfTime,
    questionOne: q1,
    questionTwo: q2,
    questionThree: q3,
    yesNoQuestionOne,
    yesNoQuestionTwo,
    yesNoQuestionThree,
    total
  } = calcState;

  useEffect(() => {
    if (!q1 || !q2 || !q3) {
      dispatch(setYesNoQuestionAnswer(null, 'yesNoQuestionOne'));
      dispatch(setYesNoQuestionAnswer(null, 'yesNoQuestionTwo'));
      dispatch(setYesNoQuestionAnswer(null, 'yesNoQuestionThree'));
    }
  }, [q1, q2, q3, dispatch]);

  const firstYesNoShown = Boolean(q1 && q2 && q3);
  const secondYesNoShown = yesNoQuestionOne !== null;
  const thirdYesNoShown = yesNoQuestionTwo !== null;

  const calcButtonEnabled =
    q1 &&
    q2 &&
    q3 &&
    yesNoQuestionOne !== null &&
    yesNoQuestionTwo !== null &&
    yesNoQuestionThree !== null;

  const handleRegisterPopupOpen = () => {
    if (isUserRegistered) return;
    dispatch(toggleRegisterPopup(true));
  };

  const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isUserRegistered) return handleRegisterPopupOpen();
    dispatch(
      setQuestionAnswer(
        parseFloat(e.target.value),
        e.target.name as keyof CalculatorState
      )
    );
  };

  const handleYesNoQuestionChange = (
    e: ChangeEvent<HTMLInputElement>,
    v: string
  ) => {
    dispatch(
      setYesNoQuestionAnswer(v as YesNo, e.target.name as keyof CalculatorState)
    );
    const fieldName = e.target.name as keyof CalculatorState;

    // scroll down to the calculate button
    if (fieldName === 'yesNoQuestionThree') {
      calcBtnRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClearContents = () => {
    dispatch(clearCalcContents());
  };

  const handleCalculate = () => {
    dispatch(calculateTotal());
    const footer = document.getElementById('ramsay-footer');

    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClose = () => {
    dispatch(toggleRegisterPopup(false));
  };

  return (
    <>
      {isRegisterPopupOpen && <RegisterPopup onClose={handleClose} />}
      {feedbackShown && (
        <FeedbackModal onClose={() => setFeedbackShown(false)} />
      )}
      <Stack component="form" position="relative">
        <CalcFormHead />
        <Typography fontWeight="bold" p={2}>
          Answer these questions and we'll calculate your task/deliverable
          estimate.
        </Typography>
        <ScrollToBottomStyled>
          <Grid container mt={1} gap={{ xs: 1, sm: 0 }}>
            <Grid item xs={12} sm={8}>
              <Typography>On the fast end (low distractions)</Typography>
              <Typography>- how many {unitOfTime}?</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="questionOne"
                onChange={handleQuestionChange}
                onClick={handleRegisterPopupOpen}
                fullWidth
                type="number"
                size="small"
                className="calc-field"
                value={q1}
              />
            </Grid>
          </Grid>
          <Grid container gap={{ xs: 1, sm: 0 }}>
            <Grid item xs={12} sm={8}>
              <Typography>On the slow end (competing priorities)</Typography>
              <Typography>- how many {unitOfTime}?</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="questionTwo"
                onChange={handleQuestionChange}
                onClick={handleRegisterPopupOpen}
                type="number"
                fullWidth
                size="small"
                className="calc-field"
                value={q2}
              />
            </Grid>
          </Grid>
          <Grid container gap={{ xs: 1, sm: 0 }}>
            <Grid item xs={12} sm={8}>
              <Typography>Your best guess (most likely)</Typography>
              <Typography>- how many {unitOfTime}?</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="questionThree"
                onChange={handleQuestionChange}
                onClick={handleRegisterPopupOpen}
                type="number"
                fullWidth
                size="small"
                className="calc-field"
                value={q3}
              />
            </Grid>
          </Grid>
          <YesNoQuestion
            onChange={handleYesNoQuestionChange}
            show={firstYesNoShown}
            question={yesNoQuestions.one}
            name="yesNoQuestionOne"
          />
          <YesNoQuestion
            onChange={handleYesNoQuestionChange}
            show={secondYesNoShown}
            question={yesNoQuestions.two.replace(
              /hours|days|weeks/g,
              (match) => unitOfTime as string
            )}
            name="yesNoQuestionTwo"
          />
          <YesNoQuestion
            onChange={handleYesNoQuestionChange}
            show={thirdYesNoShown}
            question={yesNoQuestions.three}
            name="yesNoQuestionThree"
          />
        </ScrollToBottomStyled>
        <Stack ref={calcBtnRef} my={3} px={2} gap={3}>
          <Grid container alignItems="center" gap={{ xs: 2, sm: 0 }}>
            <Grid item xs={12} sm={8} order={{ xs: 2, sm: 1 }}>
              <Button
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                variant="outlined"
                color="secondary"
                className="calc-btn"
                onClick={handleClearContents}>
                Clear Contents
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} order={{ xs: 1, sm: 2 }}>
              <Button
                onClick={handleCalculate}
                disabled={!Boolean(calcButtonEnabled)}
                fullWidth
                className="calc-btn filled"
                endIcon={<FaEquals />}>
                Calculate
              </Button>
            </Grid>
          </Grid>
          <Grid container alignItems="center" gap={{ xs: 1, sm: 0 }}>
            <Grid item xs={12} sm="auto" mr={3} ml="auto">
              <Typography fontWeight="bold" color="primary">
                Total Number of{' '}
                <Typography
                  component="span"
                  fontWeight="bold"
                  sx={{ textTransform: 'capitalize', display: 'inline' }}>
                  {unitOfTime}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                InputProps={{
                  readOnly: true,
                  sx: { bgcolor: 'white' }
                }}
                fullWidth
                size="small"
                value={total ? (total as number).toFixed(2) : ''}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" mt={{ xs: 4, sm: 6 }}>
            <Grid item xs={12} sm={4} mx="auto">
              <Button
                disabled={!total}
                fullWidth
                className="calc-btn primary"
                onClick={() => setFeedbackShown(true)}>
                Done
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default CalculatorForm;
