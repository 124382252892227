import { AppStatus } from '../user/types';

export interface SurveyListItem {
  id: string;
  email: string;
  contactId: string; // ID of the user of the WEB
  surveyAnswers: SurveyAnswer[]; // Array of answers
}

export interface FeedbackListItem {
  id: string;
  email: string;
  contactId: string; // ID of the user of the WEB
  surveyAnswers: FeedbackAnswer[]; // Array of answers // adjusted due to api naming
}

export type FeedbackAnswerType =
  | 'Very Satisfied'
  | 'Satisfied'
  | 'Neutral'
  | 'Unsatisfied'
  | 'Very Unsatisfied';

export interface UserAnswer {
  name: string;
  jobTitle: string;
  companyName: string;
  location: string;
}

export interface FeedbackAnswer {
  id: string;
  question: string;
  answer: FeedbackAnswerType;
}

export interface SurveyAnswer {
  id: string;
  question: string;
  answer: string;
}

export interface FeedbackQuestion {
  id: string;
  question: string;
  dateCreated: string;
  dateUpdated: string;
  // answer: string;
}

export interface SurveyQuestion {
  id: string;
  question: string;
  answerType: 'Text Box' | 'Drop down';
  dropDownItems?: string[];
  dateCreated: string;
  dateUpdated: string;
}

export interface FeedbackState {
  status: AppStatus;
  error: string | null;
  data: {
    feedbackQuestions: FeedbackQuestion[];
    surveyQuestions: SurveyQuestion[];
  };
}

export const LOAD_FEEDBACK_QUESTION_DATA = 'load/feedback/data';
export const SET_FEEDBACK_STATUS = 'set/feedback/status';

interface LoadFeedbackQuestionDataAction {
  type: typeof LOAD_FEEDBACK_QUESTION_DATA;
  payload: { data: FeedbackState['data'] };
}

interface SetFeedbackStatusAction {
  type: typeof SET_FEEDBACK_STATUS;
  payload: { status: AppStatus };
}

export type FeedbackAction =
  | LoadFeedbackQuestionDataAction
  | SetFeedbackStatusAction;
