// state types
export type ChatStatus = 'idle' | 'loading' | 'success' | 'error';

export interface ChatbotFAQ {
  id: string;
  question: string;
  answer: string;
  category: string;
}

export interface ChatbotState {
  status: ChatStatus;
  error: string | null;
  data: ChatbotFAQ[];
  currentCategory: string | null;
}

// action types
export const LOAD_CHATBOT_FAQ = 'chatbot/faq/load';
export const SET_CHATBOT_STATUS = 'chatbot/status/set';
export const SET_CHATBOT_CATEGORY = 'chatbot/category/set';

interface LoadChatbotFaqAction {
  type: typeof LOAD_CHATBOT_FAQ;
  payload: { chatbotFaq: ChatbotFAQ[] };
}

interface SetChatbotStatusAction {
  type: typeof SET_CHATBOT_STATUS;
  payload: { status: ChatStatus };
}

interface SetChatbotCategoryAction {
  type: typeof SET_CHATBOT_CATEGORY;
  payload: { category: string | null };
}

export type ChatbotAction =
  | LoadChatbotFaqAction
  | SetChatbotStatusAction
  | SetChatbotCategoryAction;
